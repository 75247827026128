body {
  margin: 0;
  font-family: Inter Variable, Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
  Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
