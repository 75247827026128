.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: large;
}

/* Custom styles for the date picker */
.date-picker-input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db; /* Tailwind's gray-300 */
  border-radius: 0.375rem; /* Tailwind's rounded-md */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-sm */
  font-size: 1rem; /* Tailwind's text-base */
  outline: none;
}

.date-picker-input:focus {
  border-color: #d946ef; /* Tailwind's pink-600 */
  box-shadow: 0 0 0 1px rgba(249, 168, 37, 0.5); /* Tailwind's pink-600 */
}

.react-datepicker__triangle {
  display: none; /* Hide the triangle */
}

.react-datepicker__header {
  background-color: #f9fafb; /* Tailwind's gray-50 */
  border-bottom: 1px solid #e5e7eb; /* Tailwind's gray-200 */
}

.react-datepicker__current-month {
  font-weight: bold; /* Make month bold */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #d946ef; /* Tailwind's pink-600 */
  color: white;
}

.rbc-calendar {
  position: relative;
  z-index: 0;
}